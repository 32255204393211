<template>
	<div>
		<main-header></main-header>
		<menu-intro :menuKr="menuKr" :menuEn="menuEn"></menu-intro>
		<div class="solutionpage__btn">
			<button
				@click="showCryptotab()"
				class="btn--lg btn--text-black"
				:class="{ active: cryptoActive }"
			>
				{{ navSchema }}</button
			><button
				@click="showVaccinetab()"
				class="btn--lg btn--text-black"
				:class="{ active: vaccineActive }"
			>
				{{ navVaccine }}
			</button>
		</div>
		<solution-content-crypto v-if="tabNum == 1"></solution-content-crypto>
		<solution-content-vaccine
			v-else-if="tabNum == 2"
		></solution-content-vaccine>

		<Footer />
	</div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';
import SolutionContentCrypto from '../components/SolutionContentCrypto.vue';
import SolutionContentVaccine from '../components/SolutionContentVaccine.vue';
import MenuIntro from '@/components/common/MenuIntro.vue';
export default {
	components: {
		MainHeader,
		Footer,
		SolutionContentCrypto,
		SolutionContentVaccine,
		MenuIntro,
	},
	data() {
		return {
			// menuKr: 'FCTS 솔루션',
			// menuEn: 'Solution',
			tabNum: 2,
			vaccineActive: true,
			cryptoActive: false,
		};
	},
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
		menuKr() {
			return this.computedValue === 'ko' ? 'FCTS 솔루션' : 'FCTS Solution';
		},
		menuEn() {
			return 'Solution';
		},
		navSchema() {
			return this.computedValue === 'ko' ? '암호 스키마' : 'Crypto Schema';
		},
		navVaccine() {
			return this.computedValue === 'ko'
				? '백신 FC Anti-Virus'
				: 'FC Anti-Virus';
		},
	},

	methods: {
		showCryptotab() {
			this.tabNum = 1;
			this.cryptoActive = true;
			this.vaccineActive = false;
		},
		showVaccinetab() {
			this.tabNum = 2;
			this.cryptoActive = false;
			this.vaccineActive = true;
		},
	},
};
</script>

<style></style>
